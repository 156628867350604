import React from 'react'
import tw from 'twin.macro'
import { GatsbyImage } from 'gatsby-plugin-image'
import ReadMoreButton from '../ReadMoreButton'

export default function ThreeCols(props) {
  const {
    textOne,
    textTwo,
    textThree,
    width,
    readMoreHide,
    imageOne,
    imageTwo,
    imageThree,
  } = props.content

  return (
    <section
      css={[tw`relative`, width === 'contained' && tw`container md:px-10 px-6`]}
      className={`threeCols ${width === 'space' && 'mm-px-dynamic'} ${
        readMoreHide && 'readMoreHide'
      }`}
    >
      <div
        className="mm-py-dynamic-content-sm"
        css={tw`md:flex flex-row md:space-x-16`}
      >
        <div css={tw`flex-1`}>
          {imageOne[0]?.localFile?.childImageSharp?.gatsbyImageData && (
            <GatsbyImage
              image={imageOne[0]?.localFile?.childImageSharp?.gatsbyImageData}
              alt={imageOne[0]?.title}
            />
          )}
          <div dangerouslySetInnerHTML={{ __html: textOne }}></div>
        </div>
        <div css={tw`flex-1`}>
          {imageTwo[0]?.localFile?.childImageSharp?.gatsbyImageData && (
            <GatsbyImage
              image={imageTwo[0]?.localFile?.childImageSharp?.gatsbyImageData}
              alt={imageTwo[0]?.title}
            />
          )}
          <div dangerouslySetInnerHTML={{ __html: textTwo }}></div>
        </div>
        <div css={tw`flex-1`}>
          {imageThree[0]?.localFile?.childImageSharp?.gatsbyImageData && (
            <GatsbyImage
              image={imageThree[0]?.localFile?.childImageSharp?.gatsbyImageData}
              alt={imageThree[0]?.title}
            />
          )}
          <div dangerouslySetInnerHTML={{ __html: textThree }}></div>
        </div>
      </div>
      <ReadMoreButton text="Læs mere" />
    </section>
  )
}
